<template>
    <defaultSec :title="'待添加好友列表'">
        <keep-alive>
            <el-form :inline="true" class="demo-form-inline select-wrap" size="mini">
                <el-form-item label="日期">
                    <el-date-picker
                        v-model="searchDate"
                        type="date"
                        value-format="yyyy-MM-dd">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="initList">查询</el-button>
                </el-form-item>
            </el-form>
        </keep-alive>
            <el-table :data="tableList" border size="mini">
                <el-table-column type="index" label="序号"></el-table-column>
                <el-table-column prop="mobile" label="电话"></el-table-column>
                <el-table-column prop="mobile" label="状态">
                    <template slot-scope="scope">
                        <el-tag size="medium" type="danger" v-if="scope.row.hasAdd==0">待添加</el-tag>
                        <el-tag size="medium" type="warning" v-if="scope.row.hasAdd==1">已发送邀请</el-tag>
                        <el-tag size="medium" type="info" v-if="scope.row.hasAdd==2">添加成功</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="fromTask" label="来源"></el-table-column>
                <el-table-column prop="aiCallFeeDuration" label="通话时长"></el-table-column>
                <el-table-column prop="createTime" label="AI电话拨打时间"></el-table-column>
                <el-table-column prop="addKefuId" label="添加人"></el-table-column>
                <el-table-column prop="addTime" label="添加时间"></el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button-group>
                            <el-button v-if="scope.row.hasAdd==0" type="warning" plain size="mini" @click="copyPhone(scope.row.mobile)">复制电话</el-button>
                        </el-button-group>
                    </template>
                </el-table-column>
            </el-table>
    </defaultSec>
</template>

<script>
export default {
    data() {
        return {
            searchDate:'',
            tableList:[],
        }
    },
    components:{
    },
    mounted(){
        this.formateDate();
        this.searchDate = new Date().Format("yyyy-MM-dd");
        this.initList()
    },
    methods: {
        formateDate(){
            Date.prototype.Format = function (fmt) { //author: meizz
                var o = {
                    "M+": this.getMonth() + 1, //月份
                    "d+": this.getDate(), //日
                    "h+": this.getHours(), //小时
                    "m+": this.getMinutes(), //分
                    "s+": this.getSeconds(), //秒
                    "q+": Math.floor((this.getMonth() + 3) / 3), //季度
                    "S": this.getMilliseconds() //毫秒
                };
                if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
                for (var k in o)
                    if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
                return fmt;
            }
        },
        initList() {
            this.$apiGet('lechun-cms/qiyeweixin/getNeedAddPhone',{'searchDate':this.searchDate}).then(res => {
                this.tableList = res;
                this.tableList.forEach((needAdd) => {
                    if(needAdd.aiCallFeeDuration != null) {
                        needAdd.aiCallFeeDuration = needAdd.aiCallFeeDuration + 's';
                    }
                });
            })
        },
        copyPhone(mobile){
            var input = document.createElement("input"); // 创建input对象
            input.value = mobile; // 设置复制内容
            document.body.appendChild(input); // 添加临时实例
            input.select(); // 选择实例内容
            document.execCommand("Copy"); // 执行复制
            document.body.removeChild(input); // 删除临时实例

            this.$apiGet('lechun-cms/qiyeweixin/needAddPhoneAdd',{'phone':mobile}).then(res => {
                console.log("res---",res)
                this.$message({
                    type: 'success',
                    message: '已复制到剪贴板，' + res
                });
                this.initList()
            })
        }
    }
}
</script>

<style scoped>

</style>
